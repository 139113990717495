<template>
    <div class="buy-credits">
        <div class="header" :class="expanded && 'expanded'">
            <div class="buy">
                <img src="../../assets/icons/%.png" alt="" />
                <b>Voucher e código promocional</b>
            </div>
            <img class="pointer" src="../../assets/icons/settings/close.png" alt="" v-if="expanded"
                @click="expanded = !expanded" />
            <img class="pointer" src="../../assets/icons/settings/open.png" alt="" v-else
                @click="expanded = !expanded" />
        </div>

        <div class="content" v-if="expanded">
            <h1>Verificar autenticidade do Vouncher e código promocional:</h1>
            <InputText type="text" v-model="code" placeholder="Insira seu código promocional aqui" />

            <div class="verify">
                Verificar
                <img src="../../assets/icons/verify.png" alt="">
            </div>
            <hr />
            <h1>Voucher</h1>
            <div class="voucher">
                <div class="flex flex-column">
                    <h1>Nome do Voucher - <span class="h1-code">zPAMD3A7D8</span></h1>
                    <p>Data de validade: <b>21/12/2022</b></p>
                </div>
                <div class="status ative">
                    <img src="../../assets/icons/ative.png" alt="">
                    Ativo
                </div>
            </div>
            <div class="voucher">
                <div class="flex flex-column">
                    <h1>Nome do Voucher - <span class="h1-code">zPAMD3A7D8</span></h1>
                    <p>Data de validade: <b>21/12/2022</b></p>
                </div>
                <div class="status used">
                    <img src="../../assets/icons/used.png" alt="">
                    Utilizado
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from "vue";

export default {
    setup() {
        const view = ref(true);
        const expanded = ref(false);
        const code = ref("");

        return { view, expanded, code }
    }
}
</script>

<style lang="scss" scoped>
.buy-credits {
    display: flex;
    flex-direction: column;
    padding: 20px;
    background: white;
    box-shadow: 0px 8px 24px rgba(61, 71, 102, 0.08), 0px 3px 6px rgba(61, 71, 102, 0.06);
    border-radius: 8px;
    width: 100%;
    height: max-content;
    // user-select: none;

    &:hover{
        box-shadow: 0px 8px 24px rgba(61, 71, 102, 0.15), 0px 3px 6px rgba(61, 71, 102, 0.15);
    }

    b {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: #4F4F4F;
    }

    .header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .buy {
            display: flex;
            align-items: center;
            gap: 6px;
        }
    }

    .pointer {
        cursor: pointer;
    }

    .expanded {
        border-bottom: 1px solid #F2F2F2;
        padding-bottom: 12px;
    }

    .content {
        display: flex;
        flex-direction: column;
        margin-top: 12px;

        h1 {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 700;
            font-size: 13px;
            line-height: 150%;
            color: #4F4F4F;
            margin: 0;
        }

        .verify {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 6px;
            color: #FF6A33;
            margin-top: 18px;
            cursor: pointer;
        }

        .voucher {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border: 1px solid #828282;
            padding: 16px 12px;
            border-radius: 8px;
            margin-top: 8px;


            h1 {
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 16px;
                margin: 0;
                padding: 0;
                color: #2D313D;
            }

            .h1-code {
                color: #4F4F4F;
            }

            p {
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                color: #828282;
                margin-top: 8px;
            }

            b {
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                font-weight: bolder;
                color: #2D313D;
            }
            .status{
                display: flex;
                align-items: center;
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 16px;
                gap: 6px;
            }
            .ative{
                color: #6FCF97;
            }
            .used{
                color: #F2994A;
            }
        }
    }

}

::v-deep(input) {
    height: 40px;
    border-radius: 8px;
    font-family: 'Roboto';
    font-style: normal;
    font-size: 12px;
    line-height: 14px;
    color: #2D313D;
    font-weight: bolder;

    &::placeholder {
        font-weight: 400;
        color: #BDBDBD;
    }
}
</style>