<template>
    <div class="buy-credits">
        <div class="header" :class="expanded && 'expanded'">
            <div class="buy">
                <img src="../../../assets/icons/credit_card.png" alt="" />
                <b>Cadastrar cartão de crédito/débito</b>
            </div>
            <img class="pointer" src="../../../assets/icons/settings/close.png" alt="" v-if="expanded"
                @click="expanded = !expanded" />
            <img class="pointer" src="../../../assets/icons/settings/open.png" alt="" v-else
                @click="expanded = !expanded" />
        </div>

        <div class="content" v-if="expanded">
            <Register @register="register" />
            <Cards :list="listCards" @remove="remove" />
        </div>
    </div>
</template>

<script>
import { ref } from "vue";
import Register from "./Register"
import Cards from "./Cards"

export default {
    components: {
        Register,
        Cards
    },
    setup() {
        const view = ref(true);
        const expanded = ref(false);
        const code = ref("");
        const listCards = ref([
            {name: "MARCELA S ARAUJO", last_number: "4321", type: "Crédito", default_type: false},
        ])

        const register = (obj) => {
            const oldValue = listCards.value

            listCards.value = [
                ...oldValue,
                obj
            ]
            console.log('consolenopai', obj)         
        }

        const remove = index => {
            const oldValue = listCards.value

            listCards.value = [
                ...oldValue.slice(0, index),
                ...oldValue.slice(index + 1)
            ]
        }

        return { view, expanded, code, listCards, register, remove }
    }
}
</script>

<style lang="scss" scoped>
.buy-credits {
    display: flex;
    flex-direction: column;
    padding: 20px;
    background: white;
    box-shadow: 0px 8px 24px rgba(61, 71, 102, 0.08), 0px 3px 6px rgba(61, 71, 102, 0.06);
    border-radius: 8px;
    width: calc(100% / 2);
    height: max-content;
    user-select: none;

    &:hover{
        box-shadow: 0px 8px 24px rgba(61, 71, 102, 0.15), 0px 3px 6px rgba(61, 71, 102, 0.15);
    }

    b {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: #4F4F4F;
    }

    .header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .buy {
            display: flex;
            align-items: center;
            gap: 6px;
        }
    }
    .expanded {
        border-bottom: 1px solid #F2F2F2;
        padding-bottom: 12px;
    }
    .pointer{cursor: pointer;}

    .content {
        display: flex;
        flex-direction: column;
        margin-top: 12px;

        h1 {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 700;
            font-size: 13px;
            line-height: 150%;
            color: #4F4F4F;
            margin: 0;
        }
    }

}
::v-deep(input) {
    height: 40px;
    border-radius: 8px;
    font-family: 'Roboto';
    font-style: normal;
    font-size: 12px;
    line-height: 14px;
    color: #2D313D;
    font-weight: bolder;

    &::placeholder {
        font-weight: 400;
        color: #BDBDBD;
    }
}
</style>