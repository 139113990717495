<template>
    <h1>Sua carteira</h1>
    <div class="container-extract">
        <div class="header" :class="expanded && 'expanded'">
            <div class="extract">
                <img src="../../assets/icons/extract.png" alt="" />
                <b>Extrato</b>
            </div>
            <div class="flex align-items-center" style="gap: 16px;">
                <div class="filter">
                    Filtros
                    <img src="../../assets/icons/filter.png" alt="" />
                </div>
                <img class="pointer" src="../../assets/icons/settings/close.png" alt="" v-if="expanded" @click="expanded = !expanded" />
                <img class="pointer" src="../../assets/icons/settings/open.png" alt="" v-else @click="expanded = !expanded" />
            </div>
        </div>
        <div class="flex flex-column" v-if="expanded">
            <div class="updated-balance">
                <b class="orange">Saldo atual:</b>
                <div class="balance">
                    <b class="update-balance">R$ {{ updatedbalance ? '10.521,92' : '*****' }}</b>
                    <img class="pointer" src="../../assets/icons/eye.png" alt=""
                        @click="updatedbalance = !updatedbalance" />
                </div>
            </div>
            <div class="box-table" v-for="item in data" :key="item.date">
                <h1>{{ item.date }}</h1>
                <div class="table">
                    <div class="item-row" v-for="items in item.items">
                        <div class="left">
                            <p class="id-transation">#{{ items.id }}</p>
                            <p class="date-transation">{{ items.date }}</p>
                            <b class="type">{{ items.type }}</b>
                            <p class="dependency-transation">{{ items.dependency }}</p>
                        </div>
                        <div class="right">
                            <p class="type-transation">{{ items.type_transaction }}</p>
                            <b class="value-transation" :class="!items.value_active && 'value-inative'">{{
                                    items.value_positive ? '+' : '-'
                            }} R$
                                {{ updatedbalance ? items.value : '*****' }}</b>
                        </div>
                    </div>
                </div>
            </div>
            <div class="more">
                Carregar mais
                <img src="../../assets/icons/more.png" alt="" />
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from "vue";

const data = [
    {
        date: '28/02/2021',
        items: [
            {
                id: '4321',
                date: '12:13',
                type: 'Compra de créditos',
                dependency: '',
                type_transaction: 'Compra via Pix',
                value: '2.500,50',
                value_positive: true,
                value_active: true
            },
            {
                id: '4321',
                date: '11:30',
                type: 'Agendamento realizado',
                dependency: 'Dependente nº 1234',
                type_transaction: 'Compra via Pix',
                value: '520,00',
                value_positive: false,
                value_active: true
            },
            {
                id: '4321',
                date: '18:41',
                type: 'Estorno de compra',
                dependency: '',
                type_transaction: 'Compra via Pix',
                value: '1.800,00',
                value_positive: false,
                value_active: false
            },
        ]
    },
    {
        date: '26/02/2021',
        items: [
            {
                id: '4321',
                date: '10:00',
                type: 'Agendamento realizado',
                dependency: '',
                type_transaction: 'Compra via Pix',
                value: '2.500,50',
                value_positive: true,
                value_active: true
            },
            {
                id: '4321',
                date: '10:00',
                type: 'Uso de voucher',
                dependency: '',
                type_transaction: 'Voucher promocional',
                value: '520,00',
                value_positive: false,
                value_active: true
            },
            {
                id: '4321',
                date: '10:00',
                type: 'Aquisição de plano Ommed',
                dependency: '',
                type_transaction: 'Compra via Cartão de crédito',
                value: '1.800,00',
                value_positive: false,
                value_active: true
            },
        ]
    },
]

export default {
    setup() {
        const expanded = ref(true);
        const view = ref(true);
        const updatedbalance = ref(true);

        return { expanded, data, view, updatedbalance }
    }
}
</script>

<style lang="scss" scoped>
h1 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #BDBDBD;
}

.container-extract {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px;
    background: #FFFFFF;
    box-shadow: 0px 8px 24px rgba(61, 71, 102, 0.08), 0px 3px 6px rgba(61, 71, 102, 0.06);
    border-radius: 8px;

    &:hover{
        box-shadow: 0px 8px 24px rgba(61, 71, 102, 0.15), 0px 3px 6px rgba(61, 71, 102, 0.15);
    }

    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    h1 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: #4F4F4F;
        margin: 0;
        padding: 0;
    }

    b {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: #4F4F4F;
    }

    .header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

    }

    .expanded {
        padding-bottom: 12px;
        border-bottom: 1px solid #F2F2F2;
    }

    .extract {
        display: flex;
        align-items: center;
        gap: 6px;
    }

    .balance {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        line-height: 18px;
        color: #FF6A33;
    }

    .updated-balance {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #FF6A33;
        padding: 10px 10px 10px 20px;
        border-radius: 41px;
        margin: 5px 0;

        .orange {
            color: #FF6A33;
        }

        .balance {
            display: flex;
            align-items: center;
            gap: 6px;
        }

        b {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
        }
    }

    .update-balance {
        min-width: 95px;
    }

    .pointer {
        cursor: pointer;
    }

    .box-table {
        display: flex;
        flex-direction: column;
        border-top: 1px solid #F2F2F2;
        padding-top: 16px;
        width: 100%;

        h1 {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            color: #FF6A33;
        }
    }

    .table {
        display: flex;
        flex-direction: column;
        overflow-x: auto;

        .item-row {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 8px 0;
            border-bottom: #F2F2F2 1px solid;

            width: max-content;
            min-width: 100%;
        }

        .left {
            display: flex;
            gap: 8px;
        }

        .right {
            display: flex;
            gap: 24px;
        }
    }

    .id-transation {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 24px;
        color: #333333;
    }

    .type {
        min-width: 172px;
    }

    .date-transation {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #333333;
    }

    .type-transation {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #828282;
        min-width: 183px;
    }

    .value-transation {
        min-width: 100px;
        text-align: right;
    }

    .dependency-transation {
        margin: 0 91px;
        min-width: 132px;
    }

    .more {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: #FF6A33;
        margin-top: 34px;
        margin-bottom: 13px;
        gap: 6px;
        cursor: pointer;

        &:hover {
            text-style: underline;
        }
    }

    .filter {
        display: flex;
        align-items: center;
        padding: 10px 20px;
        gap: 6px;
        border: 1px solid #E0E0E0;
        border-radius: 8px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        color: #4F4F4F;
        cursor: pointer;
    }

    b {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: #2D313D;
    }

    .value-inative {
        color: #BDBDBD;
    }
}
</style>