<template>
  <div class="limit">
    <span class="title-h1">Carteira de pagamento</span>
    <div class="content">
      <Resume />
      <Extract />
      <div class="double-items">
        <div class="column-items">
          <BuyCredits />
          <VoucherAndCode />
        </div>

        <AddCreditCard />
      </div>
    </div>
  </div>
</template>

<script>
// import { ref } from "vue";
import Resume from "./Resume.vue";
import Extract from "./Extract.vue";
import BuyCredits from "./BuyCredits.vue";
import AddCreditCard from "./AddCreditCard";
import VoucherAndCode from "./VoucherAndCode.vue";

export default {
  components: {
    Resume,
    Extract,
    BuyCredits,
    AddCreditCard,
    VoucherAndCode,
  },
  setup() {

    return {}
  }
}
</script>

<style lang="scss" scoped>
.double-items {
  display: flex;
  gap: 20px;
  margin: 16px 0;
  .column-items{
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: calc(100% / 2);
  }
}

.title-h1 {
  display: flex;
  align-items: center;
  color: var(--gray-3, #828282);
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;

  user-select: none;
}
</style>