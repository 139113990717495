<template>
    <div class="buy-credits">
        <div class="header" :class="expanded && 'expanded'">
            <div class="buy">
                <img src="../../assets/icons/buy.png" alt="" />
                <b>Compra de créditos</b>
            </div>
            <img class="pointer" src="../../assets/icons/settings/close.png" alt="" v-if="expanded"
                @click="expanded = !expanded" />
            <img class="pointer" src="../../assets/icons/settings/open.png" alt="" v-else
                @click="expanded = !expanded" />
        </div>

        <div class="content" v-if="expanded">
            <h1>Meios de compra:</h1>
            <div class="flex justify-content-between">
                <div class="items" :class="checkFilter('Pix') && 'active'" @click="changeFilter('Pix')">
                    Pix
                    <img src="../../assets/icons/pix.png" alt="">
                </div>
                <div class="items" :class="checkFilter('Cartão de crédito') && 'active'"
                    @click="changeFilter('Cartão de crédito')">
                    Cartão de crédito
                    <img src="../../assets/icons/cartao.png" alt="">
                </div>
                <div class="items" :class="checkFilter('Boleto') && 'active'" @click="changeFilter('Boleto')">
                    Boleto
                    <img src="../../assets/icons/boleto.png" alt="">
                </div>
            </div>

            <h1>Código promocional:</h1>
            <InputText type="text" v-model="code" placeholder="Insira seu código promocional aqui" />

            <div class="confirm" :class="filter && 'active'">
                Confirmar
                <img src="../../assets/icons/confirm.png" alt="">
            </div>
            <hr />
        </div>
    </div>
</template>

<script>
import { ref } from "vue";

export default {
    setup() {
        const filter = ref(null)
        const view = ref(true);
        const expanded = ref(false);
        const code = ref("");

        const changeFilter = (item) => {
            filter.value = item;
        }

        const checkFilter = (item) => {
            if (item === filter.value) {
                return true
            }
            return false
        }

        return { filter, view, expanded, changeFilter, checkFilter, code }
    }
}
</script>

<style lang="scss" scoped>
.buy-credits {
    display: flex;
    flex-direction: column;
    padding: 20px;
    background: white;
    box-shadow: 0px 8px 24px rgba(61, 71, 102, 0.08), 0px 3px 6px rgba(61, 71, 102, 0.06);
    border-radius: 8px;
    height: max-content;

    &:hover{
        box-shadow: 0px 8px 24px rgba(61, 71, 102, 0.15), 0px 3px 6px rgba(61, 71, 102, 0.15);
    }

    b {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: #4F4F4F;
    }

    .header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .buy {
            display: flex;
            align-items: center;
            gap: 6px;
        }
    }

    .expanded {
        border-bottom: 1px solid #F2F2F2;
        padding-bottom: 12px;
    }

    .pointer {
        cursor: pointer;
    }

    .content {
        display: flex;
        flex-direction: column;
        margin-top: 12px;

        h1 {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 700;
            font-size: 13px;
            line-height: 150%;
            color: #4F4F4F;
        }

        .items {
            display: flex;
            align-items: center;
            gap: 4px;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            color: #BDBDBD;
            cursor: pointer;
        }

        .active {
            filter: invert(53%) sepia(52%) saturate(3066%) hue-rotate(338deg) brightness(101%) contrast(101%);
        }

        .confirm {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 6px;
            color: #BDBDBD;
            margin-top: 18px;
        }
    }

}

::v-deep(input) {
    height: 40px;
    border-radius: 8px;
    font-family: 'Roboto';
    font-style: normal;
    font-size: 12px;
    line-height: 14px;
    color: #2D313D;
    font-weight: bolder;

    &::placeholder {
        font-weight: 400;
        color: #BDBDBD;
    }
}
</style>