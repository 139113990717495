<template>
    <div class="resume">
        <div class="flex flex-column">
            <h1>Seu saldo atual:</h1>
            <b class="balance">R$ {{ view ? '10.521,92' : '*******' }}</b>
        </div>
        <div class="flex align-items-center flex-wrap">
            <div class="item">
                <div class="header">
                    <img src="../../assets/icons/%.png" alt="">
                    <h1>Vouchers e Códigos promocionais</h1>
                </div>
                <p>{{ view ? '0' : '***' }} vouchers</p>
            </div>
            <div class="item">
                <div class="header">
                    <img src="../../assets/icons/financial-dependents.png" alt="">
                    <h1>Dependentes financeiros</h1>
                </div>
                <p>{{ view ? '2' : '***' }} dependentes cadastrados</p>

            </div>
            <div class="item">
                <div class="header">
                    <img src="../../assets/icons/credit_card.png" alt="">
                    <h1>Cartões</h1>
                </div>
                <p>{{ view ? '1' : '***' }} cartões cadastrados</p>
            </div>
            <div class="circle-orange" @click="view = !view">
                <img src="../../assets/icons/view-hide.png" alt="">
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from "vue";

export default {
    setup() {
        const view = ref(true);

        return { view }
    }
}
</script>

<style lang="scss" scoped>
.resume {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 41px 32px;
    border-radius: 15px;
    background: #16A085;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #dddee5, #dddde2, #e3dedf, #e6e0de);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #dddee5, #dddde2, #e3dedf, #e6e0de);
    margin-top: 36px;
    box-shadow: 0px 8px 24px rgba(61, 71, 102, 0.05), 0px 3px 6px rgba(61, 71, 102, 0.05);

    &:hover{
        box-shadow: 0px 8px 24px rgba(61, 71, 102, 0.15), 0px 3px 6px rgba(61, 71, 102, 0.15);
    }

    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    h1 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: #4F4F4F;
        margin: 0;
        padding: 0;
    }

    .flex-column {
        gap: 6px;

        b {}
    }

    .circle-orange {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        border-radius: 80px;
        background: #FF6A33;
        cursor: pointer;
    }

    .item {
        display: flex;
        flex-direction: column;
        border-right: 1px solid #BDBDBD;
        padding: 0 30px;
        gap: 6px;

        .header {
            display: flex;
            align-items: center;
            gap: 6px;
        }

        p {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 23px;
            color: #4F4F4F;
            min-width: 135px;
        }
    }

    .item:nth-last-child(2) {
        border: none;
        margin-right: 71px;
    }

    .balance {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        line-height: 18px;
        color: #FF6A33;
    }
}
</style>