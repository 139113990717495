<template>
    <div class="register">
        <h1>Cadastrar cartão de:</h1>
        <div class="flex justify-content-around align-items-center m24">
            <div class="item" :class="type_card === 'Crédito' && 'active'" @click="select('Crédito')">
                Crédito
                <img src="../../../assets/icons/cartao.png" alt="" />
            </div>
            <div class="item" :class="type_card === 'Débito' && 'active'" @click="select('Débito')">
                Débito
                <img src="../../../assets/icons/cartao.png" alt="" />
            </div>
        </div>

        <div class="form" v-if="type_card">
            <div class="input-group">
                Número do cartão
                <InputMask mask="9999 - 9999 - 9999 - 9999" v-model="number_card" />
            </div>
            <div class="flex gap21">
                <div class="input-group double-items">
                    Data de vencimento
                    <InputMask mask="99 / 99" v-model="due_date" />
                </div>
                <div class="input-group double-items">
                    CVV
                    <InputMask mask="9 9 9 9" v-model="cvv" />
                </div>

            </div>
            <div class="input-group">
                Nome do titular
                <InputText type="text" v-model="cardholder_name" />
            </div>
            <div class="input-group">
                Definir como padrão
                <InputSwitch v-model="define_default" />
            </div>

            
            <div class="flex m-auto gap21">
                <button class="cancel pointer" @click="close">Cancelar</button>
                <button class="pointer" @click="submit">Salvar</button>
            </div>
        </div>

        <hr />
    </div>
</template>

<script>
import { ref } from "vue";

export default {
    emit: ["register"],
    setup(_, {emit}) {
        const type_card = ref(null)
        const number_card = ref("")
        const due_date = ref("")
        const cvv = ref("")
        const cardholder_name = ref("")
        const define_default = ref(false)

        const submit = (e) => {
            e.preventDefault()
            const last_number = number_card.value.substr(21, number_card.length)

            const obj = {
                name: cardholder_name.value,
                last_number: last_number,
                type: type_card.value,
                default_type: define_default.value
            }

            if(obj.name && obj.last_number && obj.type) {
                emit('register', obj)
                close()
            }
        }

        const select = (type) => {
            if(type !== type_card.value){
                type_card.value = type
            }
        }

        const close = () => {
            type_card.value = null
            number_card.value = ""
            due_date.value = ""
            cvv.value = ""
            cardholder_name.value = ""
            define_default.value = false
            type_card.value = null
        }

        return { type_card, number_card, due_date, cvv, cardholder_name, define_default, submit, select, close }
    }
}
</script>

<style lang="scss" scoped>
.register {
    display: flex;
    flex-direction: column;

    h1 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 16px;
        color: #828282;
        margin: 0;
    }

    .input-group {
        display: flex;
        flex-direction: column;
        gap: 8px;

        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        color: #4F4F4F;
    }

    .double-items {
        width: calc(100% / 2);
    }

    .gap21 {
        gap: 21px;
    }

    .form {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    .item {
        display: flex;
        gap: 8px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: #828282;
        cursor: pointer;
    }

    .m24 {
        margin: 24px 0;
    }

    .pointer{cursor: pointer;}
    .active{
        filter: invert(53%) sepia(52%) saturate(3066%) hue-rotate(338deg) brightness(101%) contrast(101%);
    }
    .cancel{
        color: #FF4500;
        background: white;
        box-shadow: none;
    }
}

::v-deep(input) {
    height: 40px;
    border-radius: 8px;
    font-family: 'Roboto';
    font-style: normal;
    font-size: 12px;
    line-height: 14px;
    color: #2D313D;
    font-weight: bolder;

    &::placeholder {
        font-weight: 400;
        color: #BDBDBD;
    }
}

::v-deep(button) {
    width: max-content;
    background: #BDBDBD;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    border: none;
    color: #FFFFFF;
    width: 176px;
    height: 40px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>