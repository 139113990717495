<template>
    <div class="cards">
        <div class="container_card" v-for="(item, index) in list">
            <div class="item">
                <div class="flex justify-content-between w-full'">
                    <p>{{ item.name }}</p>
                    <img class="pointer" src="../../../assets/icons/config-active.png" alt="" @click="openCard(index)" v-if="visible === index" />
                    <img class="pointer" src="../../../assets/icons/edit_card.png" alt="" @click="openCard(index)" v-else />
                </div>
                <div class="flex justify-content-between w-full'">
                    <p>**** {{ item.last_number }}</p>
                    <div class="flex align-items-center gap16">
                        <p class="flag">{{ item.type }}</p>
                        <img src="../../../assets/icons/mastercard.png" alt="" />
                    </div>
                </div>
            </div>

            <div class="config" v-if="visible === index">
                <div class="remove_card" @click="remove(index)">
                    Excluir cartão de crédito
                    <img src="../../../assets/icons/delete_card.png" alt="" />
                </div>
                <div class="define_default">
                    Definir como padrão
                    <InputSwitch v-model="item.default_type" />
                </div>
            </div>
            <div class="container-white" v-else />
        </div>
    </div>
</template>

<script>
import { ref } from "vue";

export default {
    props: ["list"],
    emits: ["remove"],
    setup(_, { emit }) {
        const visible = ref(null);
        const remove = index => {
            emit("remove", index)
        }

        const openCard = index => {
            if(visible.value === index) {
                visible.value = null
            } else {
                visible.value = index
            }
        }

        return { visible, remove, openCard }
    }
}
</script>

<style lang="scss" scoped>
.cards {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .container_card {
        position: relative;
        width: 100%;
    }

    .container-white{
        height: 99px;
    }

    .item {
        position: absolute;
        display: flex;
        justify-content: center;
        flex-direction: column;
        gap: 23px;
        width: 100%;
        background: #2D9CDB;
        padding: 17px;
        border-radius: 8px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        color: white;
        z-index: 999 !important;
    }

    .config {
        width: 100%;

        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 24px 12px;
        background: white;
        border: 1px solid #BDBDBD;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        z-index: 5 !important;
        margin-top: 93px;
        width: calc(100% - 16px);
        margin-left: auto;
        margin-right: auto;
    }

    .flag {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 10px;
        line-height: 150%;
        color: white;
    }

    .remove_card {
        display: flex;
        align-items: center;
        gap: 6px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: #EB5757;
        cursor: pointer;
    }

    .define_default {
        display: flex;
        align-items: center;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        color: #4F4F4F;
        gap: 8px;
    }

    .gap8 {
        gap: 8px;
    }

    .gap16 {
        gap: 16px;
    }

    .pointer {
        cursor: pointer;
    }
}

::v-deep(input) {
    height: 40px;
    border-radius: 8px;
    font-family: 'Roboto';
    font-style: normal;
    font-size: 12px;
    line-height: 14px;
    color: #2D313D;
    font-weight: bolder;

    &::placeholder {
        font-weight: 400;
        color: #BDBDBD;
    }
}

::v-deep(button) {
    width: max-content;
    background: #BDBDBD;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    border: none;
    color: #FFFFFF;
    width: 176px;
    height: 40px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>